<template>
  <q-item dense>
    <q-item-section v-if="selected !== undefined" side>
      <q-checkbox
        :disable="disabled"
        :color="disabled ? 'grey-5' : 'primary'"
        :model-value="selected"
        dense
        @update:model-value="emit('update:selected', $event)"
      />
    </q-item-section>
    <q-item-section v-else-if="padAsIfCheckbox" side style="width: 38px" />
    <q-item-section> {{ label }} </q-item-section>
    <q-item-section
      :class="[drag ? 'cursor-grabbing' : 'cursor-grabbable', 'drag-handle']"
      side
    >
      <Icon name="mdi:drag" />
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
const props = defineProps<{
  label?: string;
  drag: boolean;
  padAsIfCheckbox?: boolean;
  selected?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (event: "update:selected", payload: typeof props.selected): void;
}>();
</script>
