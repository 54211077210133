<template>
  <ClientOnly>
    <LazyDraggableWrapper ref="menu" v-model="model" v-bind="props">
      <template #listItem="args">
        <slot name="listItem" v-bind="args" />
      </template>
    </LazyDraggableWrapper>

    <template #fallback>
      <ul>
        <div
          v-for="(opt, i) in modelValue"
          :key="i"
          :class="itemWrapperClasses ?? ''"
        >
          <slot
            name="listItem"
            v-bind="{
              item: opt,
              isDragging: false,
            }"
          >
            <DraggableListItem
              :drag="false"
              :selected="opt.selected"
              :label="opt.name"
            />
          </slot>
        </div>
      </ul>
    </template>
  </ClientOnly>
</template>

<script setup lang="ts" generic="K">
import {
  ClientOnly,
  DraggableListItem,
  LazyDraggableWrapper,
} from "#components";
import { ref, computed, type VNode } from "vue";

import type { ComponentExposed } from "~/utils/typeHelpers";
import type { DraggableListOption } from "./shared";

const props = defineProps<{
  dragGroup?: string;
  wrapperClasses?: string;
  itemWrapperClasses?: string;
  handle?: string | false;
}>();

type ListOption = DraggableListOption<K>;

defineSlots<{
  listItem(args: { item: ListOption; isDragging: boolean }): VNode;
}>();

const model = defineModel<DraggableListOption<K>[]>({ required: true });

const menu = ref<ComponentExposed<typeof LazyDraggableWrapper>>();
const drag = computed(() => (menu.value as any)?.drag ?? false);
defineExpose({
  drag,
});
</script>
